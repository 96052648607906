import { Box, Button, Card, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import ErrorAlert from "../../components/ToastyAlert/ErrorAlert";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { ToastContainer } from "react-toastify";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import axios from "axios";
import { cekPayment, cekpaymentVerification, createTransaksi, listCategory } from "../../components/Config/Api";
import styles from "./page.module.css";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import DetailTransaksi from "./DetailTransaksi";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import NotificationModal from "../../components/Modal/NotificationModal";
import { brandColor, whiteColor } from "../../components/Config/Color";
import { useNavigate } from "react-router-dom";
import PaymentModal from "../../components/Modal/PaymentModal";


const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#282C2D",
  color: "#282C2D",
  borderColor: "#282C2D",
  "&:hover": {
    backgroundColor: brandColor,
    color: brandColor,
    borderColor: brandColor,
  },
}));

const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "3px",
  },
};

const Transaksi = () => {

  const navigate = useNavigate()

  const [dataCategory, setDataCategory] = useState([]);
  const [dataCart, setDataCart] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteAll, setOpenDeleteAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState({});
  const [dataTicket, setDataTicket] = useState([]);
  const [transactionId, setTransactionId] = useState('')

  const getDataCategory = async () => {
    await axios
      .get(listCategory)
      .then((response) => {
        // console.log(response.data);
        setDataCategory(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const item = localStorage.getItem("item");

  useEffect(() => {
    if (item) {
      getDataCategory();
    } else {
      navigate('/')
    }
  }, [item]);

  const calculateGrandTotal = () => {
    if (dataCart !== 0) {
      const total = dataCart.reduce((acc, item) => {
        return acc + item.totalPrice;
      }, 0);
      return total;
    }
  };

  const handleClickMenu = (data, index) => {
    setSelectedMenu(data);
    setOpenModal(true);
  };

  const handleDelete = (data, index) => {
    // console.log(selectedIndex);

    const updatedData = [...dataCart]; // Salin array data
    updatedData.splice(selectedIndex, 1); // Hapus item berdasarkan index
    setDataCart(updatedData); // Perbarui state dengan array yang diperbarui
    console.log('updatedData',updatedData);
    ErrorAlert({
      message: "Tiket berhasil dihapus",
      position: "bottom-left",
    });
  };

  const handleClearCart = () => {
    setDataCart([]);
    ErrorAlert({
      message: "Berhasil menghapus semua tiket",
      position: "bottom-left",
    });
  };

  const handlePaymentCash = async () => {
    setLoading(true);

    const user_id = JSON.parse(item).user_id;

    const dataTransaksi = {
      transaction: {
        user_id: user_id,
        payment_type: "cash",
        amount: 0,
        payment_status: "pending",
        image_capture: "image.jpg",
      },
      ticket: dataCart.map((data) => ({
        ticketingcategory_id: data.tiket_id,
        qty: data.totalQty,
      })),
    };

    try {
      const response = await axios.post(createTransaksi, dataTransaksi, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log('Create Transaction', response.data);
      // console.log(dataTransaksi);
      if (response.data.status === "success") {
        // setDataCart([]);
        // setTimeout(() => {
        //   setOpenNotifModal(false);
        // }, 3000);
        checkPayment(response.data);
        setTransactionId(response.data.transaction_code)
      }
    } catch (error) {
      ErrorAlert({
        message: error.response.data,
        position: "top-center",
      });
      setLoading(false);
      console.log(error.response.data);
    }
  };

  const checkPayment = async (response) => {
    // console.log(response.transaction_id);
    // console.log(response.payment_status);
    await axios
      .put(cekPayment, {
        transaction_id: response.transaction_id,
        payment_status: response.status,
      })
      .then((res) => {
        console.log("verifdata", res);
        setDataTicket(res.data.data_tiket);
        // setOpenNotifModal(true);
        setOpenPaymentModal(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
        ErrorAlert({
          message: error.response.data,
          position: "top-center",
        });
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <Box display="flex" alignItems="center">
        <Box width="68.5%">
          <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: whiteColor }}>
            Daftar Tiket
          </Typography>
        </Box>
        <Box width="33%">
          <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: whiteColor }}>
            Keranjang
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid
          className={styles.listMenuContainer}
          item
          xs={12}
          sm={8}
          sx={{
            overflow: dataCategory.length >= 6 ? "auto" : "initial",
            margin: "10px auto",
            ...scrollbarStyle,
          }}
        >
          <Grid container spacing={2}>
            {dataCategory.length !== 0
              ? dataCategory.map((data, index) => {
                return (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomButton
                      variant="outlined"
                      color="warning"
                      onClick={() => {
                        handleClickMenu(data, index);
                      }}
                      sx={{
                        padding: "0px",
                        textAlign: "start",
                      }}
                    >
                      <Card
                        sx={{
                          backgroundColor: "#282C2D",
                          height: "100px",
                          maxHeight: "100px",
                          width: "210px",
                          maxWidth: "210px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "15px",
                          color: "white",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
                        }}
                      >
                        <Typography
                          // variant="caption"
                          sx={{ fontWeight: "bold", fontSize: "12px" }}
                        >
                          {data.category_name}
                        </Typography>
                        {/* <Typography sx={{ fontSize: "10px" }}>
                            {data.description
                              ? data.description
                              : "Tidak ada deskripsi"}
                          </Typography> */}
                        <Typography sx={{ fontWeight: "bold" }}>
                          {ConvertIDR(data.total)}
                        </Typography>
                      </Card>
                    </CustomButton>
                  </Grid>
                );
              })
              : undefined}
          </Grid>
        </Grid>
        <Grid
          className={styles.summeryCardContainer}
          item
          xs={12}
          sm={4}
          sx={{ margin: "10px auto" }}
        >
          <Box
            sx={{
              height: "70%",
              overflow: dataCart.length >= 5 ? "auto" : "initial",
              ...scrollbarStyle,
              mb: "15px",
            }}
          >
            {dataCart.length !== 0
              ? dataCart.map((data, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                      backgroundColor: "#282C2D",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
                      color: "white",
                      mb: "10px",
                      flexShrink: 0,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "70%",
                      }}
                    >
                      <Tooltip title="Hapus">
                        <IconButton
                          onClick={() => {
                            setSelectedIndex(index);
                            setOpenDeleteModal(true);
                          }}
                          sx={{ padding: "3px" }}
                        >
                          <Icon
                            icon="mingcute:delete-2-line"
                            color="crimson"
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Typography sx={{ fontSize: "13px" }}>
                        {data.namaTiket}
                        <span
                          style={{ fontWeight: "bold", marginLeft: "6px" }}
                        >
                          x{data.totalQty}
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        width: "30%",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "13px", fontWeight: "bold" }}
                      >
                        {ConvertIDR(data.totalPrice)}
                      </Typography>
                    </Box>
                  </Card>
                );
              })
              :
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Typography color='#9b9b9b' fontSize={14}>Keranjang masih kosong</Typography>
              </Box>
            }
          </Box>

          <Card
            sx={{
              height: "20vh",
              maxHeight: "20vh",
              backgroundColor: "#282C2D",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
              color: "white",
              display: "flex",
              flexDirection: "column",
              padding: "15px 20px",
            }}
          >
            <Box sx={{ height: "35%" }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="5px"
              >
                <Typography sx={{ fontWeight: "bold" }}>Total</Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {ConvertIDR(calculateGrandTotal())}
                </Typography>
              </Box>
              <Divider
                variant="string"
                sx={{ backgroundColor: "white", borderBottomWidth: 0.5 }}
              />
              <Box mt="50px" display="flex" alignItems="center" width="100%">
                <Box width="50%" display="flex" alignItems="center">
                  <Tooltip title="Hapus semua tiket">
                    <IconButton
                      color="error"
                      onClick={() => setOpenDeleteAll(true)}
                      disabled={dataCart.length === 0 || loading ? true : false}
                      sx={{
                        border:
                          dataCart.length === 0 || loading
                            ? "solid 1px #222"
                            : "solid 1px red",
                      }}
                    >
                      <Icon
                        icon="wpf:full-trash"
                        style={{ fontSize: "18px" }}
                      />
                      {/* <Icon icon="mdi:delete-sweep-outline" style={{fontSize:'22px'}} /> */}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  width="50%"
                  gap="35px"
                >
                  {/* <Tooltip title="Bayar QRIS">
                    <LoadingButton
                      type="submit"
                      size="medium"
                      loading={loading}
                      disabled
                      loadingIndicator={
                        <Icon
                          icon="svg-spinners:6-dots-scale-middle"
                          color={brandColor}
                          style={{ fontSize: "25px" }}
                        />
                      }
                      variant="contained"
                      // color='warning'
                      sx={{ fontWeight: "bold" }}
                    >
                      QRIS
                    </LoadingButton>
                  </Tooltip> */}
                  {/* <Tooltip title="Bayar Cash"> */}
                  <LoadingButton
                    onClick={() => handlePaymentCash()}
                    size="medium"
                    loading={loading}
                    loadingIndicator={
                      <Icon
                        icon="svg-spinners:6-dots-scale-middle"
                        color={brandColor}
                        style={{ fontSize: "25px" }}
                      />
                    }
                    variant="contained"
                    // color='warning'
                    sx={{ fontWeight: "bold", backgroundColor: brandColor }}
                    disabled={dataCart.length === 0 ? true : false}
                  >
                    Cash
                  </LoadingButton>
                  {/* </Tooltip> */}
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>

        <ConfirmationModal
          open={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
          fungsi={() => handleDelete()}
          message="Yakin ingin menghapus tiket?"
          width={300}
          height={130}
        />

        <ConfirmationModal
          open={openDeleteAll}
          close={() => setOpenDeleteAll(false)}
          fungsi={() => handleClearCart()}
          message="Yakin ingin menghapus seluruh tiket?"
          width={350}
          height={130}
        />

        <PaymentModal
          open={openPaymentModal}
          close={() => setOpenPaymentModal(false)}
          dataTransaksi={dataCart}
          success={() => setDataCart([])}
          grandTotal={calculateGrandTotal()}
          transactionId={transactionId}
        />

        <DetailTransaksi
          open={openModal}
          close={() => setOpenModal(false)}
          selectedData={selectedMenu}
          cart={dataCart}
        />

        {/* Tampilkan komponen StrukPrint sebagai konten yang akan dicetak */}
        {/* <StrukPrint
          ref={componentRef} // Set ref untuk komponen yang akan dicetak
          dataCart={dataCart}
          selectedMenu={selectedMenu}
          grandTotal={ConvertIDR(calculateGrandTotal())}
        /> */}

        <ToastContainer />
      </Grid>
    </div>
  );
};

export default Transaksi;
