import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { brandColor } from "../Config/Color";



const ConfirmationModal = ({
  open,
  close,
  selectedData,
  fungsi,
  message,
  width,
  height
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    height: height,
    bgcolor: "#282C2D",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    color: "white",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {message}
          </Box>
          <Box
            sx={{
              // marginTop: '20px',
              display: "flex",
              justifyContent: "space-between",
              // marginRight: '10px',
              marginTop: "25px",
              // marginBottom: "5px",
            }}
          >
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => {
                close();
                // clearFormData();
              }}
              sx={{
                marginRight: "15px",
                width: "80px",
                fontSize: "13px",
                fontWeight: "bold",
                "&.Mui-disabled": {
                  color: "#111", // Set the font color to white when disabled
                },
              }}
              disabled={loading}
            >
              Tidak
            </Button>
            <LoadingButton
              type="submit"
              size="small"
              loading={loading}
              loadingIndicator={
                <Icon
                  icon="svg-spinners:6-dots-scale-middle"
                  color={brandColor}
                  style={{ fontSize: "25px" }}
                />
              }
              variant="contained"
              // color='warning'
              sx={{ fontWeight: "bold", backgroundColor:brandColor }}
              onClick={() => {
                setLoading(true)
                setTimeout(() => {
                  fungsi();
                  close();
                  setLoading(false)
                }, 1500);
              }}
            >
              Ya
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
