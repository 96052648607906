import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import ConvertIDR from "../ConvertIDR/ConvertIDR";
// import ConvertIDR from "@/components/ConvertIDR/ConvertIDR";
import { Icon } from "@iconify/react";
import { useReactToPrint } from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import TransactionReceipt from "../../pages/transaksi/TransactionReceipt";
import { brandColor } from "../Config/Color";
import DetailTransaksi from "../../pages/transaksi/DetailTransaksi";
import NotificationModal from "./NotificationModal";
import { LoadingButton } from "@mui/lab";
import ErrorAlert from "../ToastyAlert/ErrorAlert";
import SuccessAlert from "../ToastyAlert/SuccessAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "#282C2D",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  color: "white",
};

const PaymentModal = ({
  open,
  close,
  success,
  dataTransaksi,
  grandTotal,
  transactionId,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [detailTransaksiModal, setDetailTransaksiModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({
    kembalian: '',
    dibayar: ''
  })
  const [totalDibayar, setTotalDibayar] = useState(0)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmitPayment = (e) => {
    e.preventDefault();

    console.log('dibayar', newData.dibayar)
    console.log('grandtotal', grandTotal)


    setLoading(false)
    if (newData.dibayar >= grandTotal) {
      setLoading(true)
      setTotalDibayar(newData.dibayar)
      setTimeout(() => {
        close()
        setDetailTransaksiModal(true)
        SuccessAlert({
          message: 'Transaksi Berhasil',
          position: 'top-center'
        })
        setNewData({
          dibayar: ''
        })
        setLoading(false)
      }, 2000);
    } else if (newData.dibayar < grandTotal) {
      ErrorAlert({
        message: 'Jumlah uang dibayar tidak cukup',
        position: 'top-center'
      })
      setLoading(false)
    } else {
      ErrorAlert({
        message: 'Jumlah uang dibayar tidak boleh kosong',
        position: 'top-center'
      })
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal
        open={open}
        // onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmitPayment} >
            <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Di Bayar"
              placeholder="Total dibayarkan. . ."
              value={newData.dibayar}
              onChange={(e) => handleChange(e)}
              name="dibayar"
              autoComplete="off"
              autoFocus
              required
              sx={{
                gridColumn: "span 4",
                // mt: "20px",
                "& .MuiFilledInput-input": {
                  color: "white",
                  // fontSize:'13px'
                },
                "& .MuiFilledInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInputLabel-root": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                  color: "white",
                },
                "& .MuiFilledInput-underline:hover:before": {
                  borderBottomColor: brandColor,
                },
                "& .MuiFilledInput-underline:hover:after": {
                  borderBottomColor: brandColor,
                },
                "& .MuiFilledInput-underline:focus": {
                  borderBottomColor: brandColor,
                },
                "& .MuiFilledInput-underline:focus:before": {
                  borderBottomColor: brandColor,
                },
                "& .MuiFilledInput-underline:focus:after": {
                  borderBottomColor: brandColor,
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
              }}
              InputProps={{
                classes: {
                  root: "MuiFilledInput-root",
                  focused: "MuiFilledInput-focused",
                },
              }}
              InputLabelProps={{
                classes: {
                  root: "MuiInputLabel-root",
                  focused: "MuiInputLabel-focused",
                },
              }}
            />

            <Box
              mt="25px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap="20px"
            >
              <Button
                onClick={() => {
                  // success();
                  close();
                }}
                size="small"
                variant="contained"
                color="error"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                Kembali
              </Button>
              <LoadingButton
                // onClick={() => handleSubmitPayment()}
                type="submit"
                size="medium"
                loading={loading}
                loadingIndicator={
                  <Icon
                    icon="svg-spinners:6-dots-scale-middle"
                    color={brandColor}
                    style={{ fontSize: "25px" }}
                  />
                }
                variant="contained"
                // color='warning'
                sx={{ fontWeight: "bold", backgroundColor: brandColor }}
              // disabled={dataCart.length === 0 ? true : false}
              >
                Bayar
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
      <NotificationModal
        open={detailTransaksiModal}
        close={() => setDetailTransaksiModal(false)}
        dataTransaksi={dataTransaksi}
        success={success}
        grandTotal={grandTotal}
        dibayar={totalDibayar}
        transactionId={transactionId}
      />
    </div >
  );
};

export default PaymentModal;
