import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "react-toastify/dist/ReactToastify.css";
import QRCodeGenerator from "../../components/qrcode/QrCode";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import moment from "moment";

const TransactionReceipt = forwardRef(
  ({ dataTransaksi, grandTotal, transactionId, diBayar }, ref) => {

    const [cashier, setCashier] = useState('')

    const item = localStorage.getItem('item')

    useEffect(() => {
      setCashier(JSON.parse(item).username)
      // console.log('asda', cashierName);
    }, [])

    // console.log('tesss', transactionId);
    return (
      <div ref={ref}>
        {/* Your receipt content rendering code here */}
        {/* You can use the dataTransaksi and grandTotal props to populate the receipt */}
        <Box
          style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 15px 0px 15px",
          }}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: '20px' }}
          >
            The Blooms Garden
          </Typography>
          <Typography
            style={{ fontSize: '12px' }}
          >
            By ulun danu beratan Bedugul Bali
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: 'flex-start',
            padding: "20px 15px 4px 15px",
          }}
        >
          <Typography
            style={{ textAlign: "center", fontSize: "10px" }}
          >
            {" "}
            Kasir : <span style={{ fontWeight: 'bold' }} >{cashier}</span>
          </Typography>
          <Typography
            style={{ textAlign: "center", fontSize: "10px" }}
          >
            {' '}
            Tanggal : <span style={{ fontWeight: 'bold' }}>{`${moment(new Date()).format('YYYY/MM/DD')}`}</span>
          </Typography>
          <Typography
            style={{ textAlign: "center", fontSize: "10px" }}
          >
            {' '}
            Waktu : <span style={{ fontWeight: 'bold' }}>{`${moment(new Date()).format('HH:mm:ss')}`}</span>
          </Typography>
        </Box>
        <Divider
          variant="string"
          sx={{
            backgroundColor: "#111",
            borderBottomWidth: 0.5,
            // mt: "2px",
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "3px 15px 3px 15px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItem: "center",
              width: "70%",
            }}
          >
            <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
              Daftar Pembelian
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItem: "center",
              width: "30%",
            }}
          >
            <Box>
              <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
                Qty
              </Typography>
            </Box>
            <Box>
              <Typography style={{ fontSize: "11px", fontWeight: "bold" }}>
                Total
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          variant="string"
          sx={{
            backgroundColor: "#111",
            borderBottomWidth: 0.5,
            // mt: "2px",
          }}
        />

        <Box
          style={{
            marginTop: "5px",
            // marginBottom: '10px'
          }}
        >
          {dataTransaksi.map((data, index) => {
            return (
              <Box
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  gap: "5px",
                  padding: "0px 15px 0px 15px",
                  width: "100%",
                }}
              >
                <Box
                  width="70%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={index}
                >
                  <Typography style={{ fontSize: "10px" }}>
                    {data.namaTiket}
                  </Typography>
                </Box>
                <Box
                  width="30%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography style={{ fontSize: "10px" }}>
                    {data.totalQty}
                  </Typography>
                  <Typography style={{ fontSize: "10px" }}>
                    {ConvertIDR(data.totalPrice)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Divider
            variant="string"
            style={{
              backgroundColor: "#111",
              borderBottomWidth: 0.5,
              // mt: "10px",
              // paddingTop:'10px'
            }}
          />
          <Box
            style={{
              // padding: "0px 15px 0px 15px",
              display: "flex",
              flexDirection: 'column',
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              padding: "0px 15px 0px 15px",
            }}
          >

            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "space-between",
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "space-between",
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  Total
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  {ConvertIDR(grandTotal)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "space-between",
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  Dibayar
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  {ConvertIDR(diBayar)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "space-between",
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  Kembalian
                </Typography>
                <Typography sx={{ fontSize: "10px", fontWeight: "bold" }}>
                  {ConvertIDR(diBayar - grandTotal)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Box style={{ marginTop: "20px", padding:'0px' }}>
          ---------------------------------------------------
        </Box> */}
        <Box>
          <QRCodeGenerator transactionId={transactionId} />
        </Box>
      </div>
    );
  }
);

export default TransactionReceipt;