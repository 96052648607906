"use client";

import React, { useEffect, useState } from "react";
import styles from "./page.module.css";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";


import moment from "moment/moment";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LoadingButton } from "@mui/lab";
import { ToastContainer } from "react-toastify";
import { cekpaymentVerification, detailTransactions, listTransactionsCashier } from "../../components/Config/Api";

import "moment/locale/id";
import SuccessAlert from "../../components/ToastyAlert/SuccessAlert";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import { Icon } from "@iconify/react";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { brandColor, whiteColor } from "../../components/Config/Color";
import { useNavigate } from "react-router-dom";
moment.locale("id");


const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#282C2D",
  color: "#282C2D",
  borderColor: "#282C2D",
  "&:hover": {
    backgroundColor: brandColor,
    color: brandColor,
    borderColor: brandColor,
  },
}));

const DaftarTransaksi = () => {

  const navigate = useNavigate()

  const [dataTransactions, setDataTransactions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [detailTransaksi, setDetailTransaksi] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSum, setTotalSum] = useState("");
  const [openRefundModal, setOpenRefundModal] = useState(false);

  const getData = async () => {
    await axios
      .post(listTransactionsCashier, {
        payment_status: 'success'
      })
      .then((response) => {
        console.log('daftar transaksi', response.data);
        setDataTransactions(response.data);
        // setDetailTransaksi([])
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const item = localStorage.getItem("item");

  useEffect(() => {
    if (item) {
      getData();
    }else{
      navigate('/')
    }
  }, [item]);

  const handleCardClick = (index) => {
    setSelectedIndex(index);
  };

  const handleSelectRow = (ticket_code, rowData) => {
    if (selectedRows.includes(ticket_code)) {
      setSelectedRows(selectedRows.filter((code) => code !== ticket_code));
      setSelectedData(
        selectedData.filter((data) => data.ticket_code !== ticket_code)
      );
    } else {
      setSelectedRows([...selectedRows, ticket_code]);
      setSelectedData([...selectedData, rowData]);
    }
  };

  const handleSelectedData = async (data, index) => {
    // console.log("handle data", data);

    await axios
      .get(`${detailTransactions}/${data.transaction_id}`)
      .then((response) => {
        console.log("asd", response.data);
        setDetailTransaksi(response.data.data_tiket);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefund = () => {
    setOpenRefundModal(true);
    const total = detailTransaksi.reduce(
      (total, item) => total + parseInt(item.total),
      0
    );
    setTotalSum(total);
    // console.log('selectedRows', selectedRows);
  };

  const updateTransaksi = async () => {
    var transaction_id = "";
    detailTransaksi.map((data) => {
      transaction_id = data.transaction_id;
    });
    // console.log("detailTransaksi", transaction_id);
    // console.log("detailTransaksi", detailTransaksi.transaction_id);
    await axios
      .put(cekpaymentVerification, {
        transaction_id: transaction_id,
        payment_status: "refund",
      })
      .then((response) => {
        console.log('refund', response.data);
        getData()
        setSelectedIndex(null)
        setDetailTransaksi([])
        SuccessAlert({
          message: 'Berhasil melakukan refund',
          position: 'top-center'
        })
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <div className={styles.container}>
      {dataTransactions.length !== 0 ? (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} md={7} lg={3}>
            <Box sx={{ mb: "10px", ml: "10px", mt: '20px' }}>
              <Typography sx={{ color: whiteColor }} >Daftar Transaksi</Typography>
            </Box>
            {dataTransactions.length > 5 ? (
              <Box
                sx={{
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "77vh",
                  padding: "0px 8px",
                  "&::-webkit-scrollbar": {
                    width: "6px", // Set the width of the scrollbar
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: brandColor, // Set the thumb color
                    borderRadius: "3px", // Set the thumb border radius
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: brandColor, // Set the thumb color on hover
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#282C2D", // Set the track color
                  },
                  "&::-webkit-scrollbar-track:hover": {
                    background: "#282C2D", // Set the track color on hover
                  },
                }}
              >
                {dataTransactions.map((data, index) => (
                  <CustomButton
                    key={index}
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      handleSelectedData(data, index);
                      handleCardClick(index);
                    }}
                    sx={{
                      padding: "0px",
                      textAlign: "start",
                      border: `2px solid ${selectedIndex === index ? brandColor : "#282C2D"
                        }`, // Change border color based on selectedIndex
                    }}
                  >
                    <Card
                      sx={{
                        backgroundColor: "#282C2D",
                        width: "100%",
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "15px",
                        color: "white",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "65%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <Typography>
                            {moment(data.purchased_at).format("Do MMM YYYY")}{" "}
                            {moment(data.purchased_at).format("HH:mm:ss")}
                          </Typography>
                          <Typography>{data.payment_type}</Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "35%",
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "end",
                            alignSelf: "end",
                            // backgroundColor:'orange'
                          }}
                        >
                          <Typography sx={{ textAlign: "end" }}>
                            {ConvertIDR(data.amount)}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </CustomButton>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "77vh",
                  padding: "0px 8px",
                }}
              >
                {dataTransactions.map((data, index) => (
                  <CustomButton
                    key={index}
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      handleSelectedData(data, index);
                      handleCardClick(index);
                      // console.log(data);
                    }}
                    sx={{
                      padding: "0px",
                      textAlign: "start",
                      border: `2px solid ${selectedIndex === index ? brandColor : "#282C2D"
                        }`, // Change border color based on selectedIndex
                    }}
                  >
                    <Card
                      sx={{
                        backgroundColor: "#282C2D",
                        width: "100%",
                        maxWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "15px",
                        color: "white",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "70%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                          }}
                        >
                          <Typography>{data.purchased_at}</Typography>
                          <Typography>{data.payment_type}</Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "30%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                        >
                          <Typography>{ConvertIDR(data.amount)}</Typography>
                        </Box>
                      </Box>
                    </Card>
                  </CustomButton>
                ))}
              </Box>
            )}
          </Grid>
          {detailTransaksi.length !== 0 ? (
            <Grid item xs={12} md={7} lg={9}>
              <Box sx={{ mt: '23px' }}>
                <Typography sx={{ color: whiteColor }} >Detail Transaksi</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <LoadingButton
                  // onClick={() => handlePaymentCash()}
                  size="small"
                  loading={loading}
                  loadingIndicator={
                    <Icon
                      icon="svg-spinners:6-dots-scale-middle"
                      color={brandColor}
                      style={{ fontSize: "25px" }}
                    />
                  }
                  variant="contained"
                  // color='warning'
                  sx={{ fontWeight: "bold" }}
                  // disabled={dataCart.length === 0 ? true : false}
                  disabled
                >
                  Reprint
                </LoadingButton>
                <LoadingButton
                  onClick={() => handleRefund()}
                  size="small"
                  loading={loading}
                  loadingIndicator={
                    <Icon
                      icon="svg-spinners:6-dots-scale-middle"
                      color={brandColor}
                      style={{ fontSize: "25px" }}
                    />
                  }
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: brandColor,
                    // color: "#111",
                  }}
                  disabled={detailTransaksi.length === 0 ? true : false}
                >
                  Refund
                </LoadingButton>
              </Box>
              <Card
                sx={{
                  backgroundColor: "#282C2D",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
                  mt: "10px",
                }}
              >
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650, backgroundColor: "#282C2D" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                          No
                        </TableCell>
                        {/* <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                     Pilih Data
                   </TableCell> */}
                        <TableCell
                          align="left"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Nama Category
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          Kode Tiket
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            pr: "40px",
                          }}
                        >
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailTransaksi
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "white" }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ color: "white", width: "30%" }}
                            >
                              {row.category_name}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "white" }}>
                              {row.ticket_code}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{ color: "white", fontWeight: "bold" }}
                            >
                              {ConvertIDR(row.total)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 8]}
                    component="div"
                    count={detailTransaksi.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      backgroundColor: "#282C2D",
                      color: "white",
                      "& .MuiIconButton-root, & .MuiTablePagination-selectIcon":
                      {
                        color: "white",
                      },
                      "& .MuiIconButton-root": {
                        color: "white",
                      },
                    }}
                  />
                </TableContainer>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} md={7} lg={9}>
              <Box
                sx={{
                  // backgroundColor:'orange',
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon
                  icon="fa-solid:hand-point-left"
                  style={{ fontSize: "20px", marginRight: "10px", color: whiteColor }}
                />
                <Typography sx={{ color: whiteColor }}>Silahkan pilih salah satu transaksi.</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box
          sx={{
            // backgroundColor:'orange',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Icon
            icon="svg-spinners:6-dots-rotate"
            style={{ fontSize: "40px", color: brandColor }}
          />
        </Box>
      )}
      <ConfirmationModal
        open={openRefundModal}
        close={() => setOpenRefundModal(false)}
        fungsi={updateTransaksi}
        message={
          <Typography>
            Apakah Anda yakin ingin refund transaksi senilai{" "}
            <Typography component="span" fontWeight="bold">
              {ConvertIDR(totalSum)}
            </Typography>{" "}
            ?
          </Typography>
        }
      />
      <ToastContainer />
    </div>
  );
};

export default DaftarTransaksi;
