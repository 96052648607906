
import { toast } from 'react-toastify';

const InfoAlert = ({message, position}) => {
    toast.info(message, {
      position: position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
}

export default InfoAlert;