"use client";

import React, { useEffect, useState } from "react";
import styles from "./page.module.css";
import {
  Box,
  Card,
  Grid,
  Item,
  Typography,
  useMediaQuery,
  TablePagination,
  Tooltip,
  IconButton,
  TextField,
  Popover,
  MenuItem,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker } from "@mui/x-date-pickers";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import SearchBar from "material-ui-search-bar";
import moment from "moment";
import TransaksiModal from "./TransaksiModal";
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { reportTransactions } from "../../components/Config/Api";
import ErrorAlert from "../../components/ToastyAlert/ErrorAlert";
import InfoAlert from "../../components/ToastyAlert/InfoAlert";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import LogoParkways from '../../assets/Icon/logo-parkways.png'
import LogoWeb from '../../assets/Icon/web.png'
import { brandColor, orangeColor, whiteColor } from "../../components/Config/Color";
import "moment/locale/id";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";


moment.locale("id");

const Report = () => {

  const navigate = useNavigate()
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dataTransaction, setDataTransaction] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searched, setSearched] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [openDetailTransaksiModal, setOpenDetailTransaksiModal] =
    useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [openPopover, setOpenPopover] = useState(null);

  const getData = async () => {
    await axios
      .post(reportTransactions, {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(finishDate).format("YYYY-MM-DD"),
      })
      .then((response) => {
        console.log(response.data);
        setDataTransaction(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const item = localStorage.getItem("item");

  useEffect(() => {
    if (item) {
      getData();
    } else {
      navigate('/')
    }
  }, [item]);

  const FilterByDateRange = () => {
    /* function for adding 2 textfields for date range */
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          // p="10px"
          mt={isNonMobile ? "10px" : undefined}
          sx={{
            "& > div": {
              gridColumn: isNonMobile ? undefined : "span 4",
            },
          }}
        >
          <DatePicker
            label="Dari Tanggal"
            sx={{
              gridColumn: "span 2",
              "& .MuiFormLabel-root": {
                color: "white",
                "&.Mui-focused": {
                  color: brandColor, // Change label color when active
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                  // padding: "20px !important",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: brandColor, // Change outline border color when active
                },
              },
              "& .MuiInputBase-input": {
                color: "white",
                padding: "10px !important",
              },
              "& .MuiIconButton-root": {
                color: "white",
                "&:hover": {
                  color: brandColor, // Change icon color on hover
                },
                "&.Mui-focused": {
                  color: brandColor, // Change icon color when active
                },
              },
            }}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
          />
          <DatePicker
            label="Sampai Tanggal"
            sx={{
              gridColumn: "span 2",
              "& .MuiFormLabel-root": {
                color: "white",
                "&.Mui-focused": {
                  color: brandColor, // Change label color when active
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: brandColor, // Change outline border color when active
                },
              },
              "& .MuiInputBase-input": {
                color: "white",
                padding: "10px !important",
              },
              "& .MuiIconButton-root": {
                color: "white",
                "&:hover": {
                  color: brandColor, // Change icon color on hover
                },
                "&.Mui-focused": {
                  color: brandColor, // Change icon color when active
                },
              },
            }}
            value={finishDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setFinishDate(newValue);
            }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent={isNonMobile ? "end" : "end"}
          margin="20px 0 10px 0"
        // background='#012D70'
        >
          <LoadingButton
            type="submit"
            size="medium"
            endIcon={<Icon icon="ic:baseline-manage-search" />}
            loading={loading}
            loadingIndicator={
              <Icon
                icon="svg-spinners:6-dots-scale-middle"
                color={brandColor}
                style={{ fontSize: "25px" }}
              />
            }
            variant="contained"
            style={{
              color: "white",
              fontWeight: "bold",
              padding: "4px 10px",
              borderRadius: "5px",
              backgroundColor: brandColor,
            }}
          >
            Cari Data
          </LoadingButton>
        </Box>
      </form>
    );
  };

  const requestSearch = (searchedVal) => {
    setSearchQuery(searchedVal);
    const filteredRows = dataTransaction.filter((row) => {
      return (
        row.amount.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.tanggal.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (searchedVal.toLowerCase() === "lunas" &&
          row.payment_status.toLowerCase() === "success") ||
        (searchedVal.toLowerCase() === "batal" &&
          row.payment_status.toLowerCase() === "cancel") ||
        (searchedVal.toLowerCase() === "refund" &&
          row.payment_status.toLowerCase() === "refund") ||
        (searchedVal.toLowerCase() === "pending" &&
          row.payment_status.toLowerCase() === "pending") ||
        row.payment_type.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setFilteredData(filteredRows);
    setPage(0); // Reset to the first page after filtering
  };

  const cancelSearch = () => {
    setSearchQuery("");
    setFilteredData(dataTransaction);
    setSearched("");
    setPage(0); // Reset to the first page after canceling search
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(reportTransactions, {
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(finishDate).format("YYYY-MM-DD"),
      })
      .then((response) => {
        console.log(response.data);
        setDataTransaction(response.data);
        setFilteredData(response.data);
        setLoading(false);
        if(response.data.length === 0) {
          ErrorAlert({
            message:'Tidak ada data',
            position:'top-center'
          })
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenMenuExportData = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  // Fungsi untuk export data ke format file CSV
  const handleExportExcel = () => {
    if (filteredData.length !== 0) {
      // Membuat objek custom header
      const exportData = filteredData.map((item) => ({
        'Tanggal & Waktu': item.tanggal,
        'Tipe Pembayaran': item.payment_type,
        Status:
          item.payment_status === 'success'
            ? 'Lunas'
            : item.payment_status === 'pending'
              ? 'Pending'
              : item.payment_status === 'cancel'
                ? 'Batal'
                : item.payment_status === 'refund'
                  ? 'Refund'
                  : undefined,
        'Total': ConvertIDR(item.amount),
      }));

      // Menghitung total
      const grandTotal = filteredData.reduce((total, item) => total + parseInt(item.amount, 10), 0);

      // Membuat objek totalRow
      const totalRow = {
        'Tanggal & Waktu': 'GrandTotal',
        'Tipe Pembayaran': '',
        Status: '',
        'Total': ConvertIDR(grandTotal),
      };

      // Menambahkan totalRow ke exportData
      exportData.push(totalRow);

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'LaporanTransaksi');

      XLSX.writeFile(workBook, 'LaporanTransaksi.xlsx');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  // Fungsi untuk export data to PDF
  const handleExportPdf = () => {
    const doc = new JsPDF();

    const columns = [
      {
        title: 'Tanggal & Waktu',
        field: 'tanggal'
      },
      {
        title: 'Tipe Pembayaran',
        field: 'payment_type'
      },
      {
        title: 'Status',
        field: 'payment_status'
      },
      {
        title: 'Total',
        field: 'amount'
      },

    ]

    // Mendefinisikan logo
    const logoUrl = LogoParkways;
    const logoWidth = 16;
    const logoHeight = 16;

    if (filteredData.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(13);
      doc.setFont('helvetica', 'bold');
      doc.text('LaporanTransaksi', titleX, titleY);
      // doc.text('Laporan Pendapatan', 15, 8)

      // Menambahkan logo
      doc.addImage(logoUrl, 'PNG', 155, 10, logoWidth, logoHeight);
      // doc.setFont('helvetica', 'bold');
      doc.setTextColor('#1E458C');
      doc.text('Parkways', 170, 18);
      doc.setFontSize(5);
      doc.text('Digital Parking Transformation', 170, 21.5);

      doc.setTextColor('black');
      doc.setFontSize(8);
      // doc.setFont('helvetica', 'bold')
      // doc.text(location.location_name ? `${location.location_name}` : 'Tidak ada nama lokasi', 14, 35);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(7);
      doc.text(
        `Periode ${moment(startDate).format('Do MMM YYYY')} - ${moment(finishDate).format('Do MMM YYYY')}`,
        14,
        39.5
      );

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(13, titleY + 2.5, doc.internal.pageSize.getWidth() - 13, titleY + 2);

      const startY = 42;

      doc.autoTable({
        startY,
        showHead: 'firstPage',
        rowPageBreak: 'avoid',
        headStyles: {
          valign: 'middle',
          halign: 'left',
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          purchased_at: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellWidth: 55,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
          payment_type: {
            halign: 'left',
            valign: 'middle',
            fontSize: 9,
            cellWidth: 50,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          payment_status: {
            halign: 'left',
            valign: 'middle',
            cellWidth: 50,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          amount: {
            halign: 'right',
            valign: 'middle',
            cellWidth: 20,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: 'bold',
          },
        },

        columns: columns.map((col) => ({
          header: col.title,
          dataKey: col.field,
        })),

        body: filteredData.map((data) => ({
          tanggal: data.tanggal,
          payment_type: data.payment_type === 'qris' ? 'QRIS' : 'Cash',
          payment_status:
            data.payment_status === 'success'
              ? 'Lunas'
              : data.payment_status === 'pending'
                ? 'Pending'
                : data.payment_status === 'cancel'
                  ? 'Batal'
                  : data.payment_status === 'refund'
                    ? 'Refund'
                    : undefined,
          amount: ConvertIDR(data.amount),
        })),
      });

      const grandTotal = filteredData.reduce((total, data) => total + parseInt(data.amount, 10), 0);

      // console.log('grandTotal', grandTotal);

      doc.autoTable({
        body: [
          [
            {
              content: 'Grand Total',
              styles: {
                halign: 'left',
                valign: 'middle',
                fontSize: 9,
                fillColor: '#FFFFFF',
                lineWidth: { bottom: 0.1 },
                lineColor: '#000000',
                fontStyle: 'bold',
              },
            },
            {
              content: ConvertIDR(grandTotal),
              styles: {
                halign: 'right',
                valign: 'middle',
                fontSize: 9,
                fillColor: '#FFFFFF',
                lineWidth: { bottom: 0.1 },
                lineColor: '#000000',
                fontStyle: 'bold',
              },
            },
          ],
        ],
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      const currentTime = moment(new Date()).format('HH:mm:ss');

      // Mendefinisikan ikon web
      const webIconUrl = LogoWeb;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(webIconUrl, 'PNG', webIconX1, webIconY, webIconWidth, webIconHeight);

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = 'www.parkways.id';
      const rightFooterText2 = 'www.bssparking.com';

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor('gray');

      // Teks footer di sebelah kiri
      doc.text(leftFooterText, 8 + webIconWidth + 2, doc.internal.pageSize.getHeight() - 10);
      doc.setFont('helvetica', 'bold');

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX1 = doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(rightFooterText1, rightFooterTextX1, doc.internal.pageSize.getHeight() - 10);

      // Menambahkan ikon web di sebelah kanan www.parkways.id
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(webIconUrl, 'PNG', webIconX2, webIconY, webIconWidth, webIconHeight);

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(rightFooterText2, rightFooterTextX2, doc.internal.pageSize.getHeight() - 10);

      doc.save('LaporanTransaksi.pdf');
      InfoAlert({
        message: 'Laporan tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid
          item
          xs={12}
          md={7}
          lg={12}
          sx={
            {
              // backgroundColor: "orange",
            }
          }
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" color={whiteColor}>Laporan Transaksi</Typography>
            </Grid>
            {/* <Grid item>
              <Typography variant="h5">Laporan</Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          lg={12}
          sx={
            {
              // backgroundColor: "blue",
            }
          }
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sx={{ mt: "50px" }}>
              <Typography color={whiteColor} fontSize={14}>
                Periode {moment(startDate).format("Do MMM YYYY")} -{" "}
                {moment(finishDate).format("Do MMM YYYY")}
              </Typography>
            </Grid>
            <Grid item sx={{ mt: "-40px" }}>
              <FilterByDateRange />
            </Grid>
          </Grid>
          <Card
            sx={{
              backgroundColor: "#282C2D",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.50)",
              mt: "10px",
            }}
          >
            <TableContainer component={Paper}>
              <Box
                sx={{
                  p: "20px 20px 10px 20px",
                  backgroundColor: "#282C2D",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    style={{ width: "40%" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Export">
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={handleOpenMenuExportData}
                    >
                      <Icon
                        icon="line-md:download-loop"
                        style={{ fontSize: "25px", color: "#fff" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Table
                sx={{ minWidth: 650, backgroundColor: "#282C2D" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      No
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Tanggal & Waktu
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Tipe Pembayaran
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        pr: "40px",
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Aksi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "white" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.tanggal}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.payment_type === "cash" ? "Cash" : "QRIS"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.payment_status === "success" ? (
                            <span
                              style={{
                                color: "lightgreen",
                                fontWeight: "bold",
                              }}
                            >
                              Lunas
                            </span>
                          ) : row.payment_status === "pending" ? (
                            <span
                              style={{ color: orangeColor, fontWeight: "bold" }}
                            >
                              Pending
                            </span>
                          ) : row.payment_status === "cancel" ? (
                            <span
                              style={{ color: "crimson", fontWeight: "bold" }}
                            >
                              Batal
                            </span>
                          ) : row.payment_status === "refund" ? (
                            <span
                              style={{ color: "white", fontWeight: "bold" }}
                            >
                              Refund
                            </span>
                          ) : undefined}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          {ConvertIDR(row.amount)}
                        </TableCell>
                        <TableCell align="center" sx={{ color: "white" }}>
                          <Tooltip title="Lihat Detail">
                            <IconButton
                              sx={{ padding: "0px !important" }}
                              onClick={() => {
                                setSelectedData(row);
                                setOpenDetailTransaksiModal(true);
                              }}
                            >
                              <Icon
                                icon="icon-park-outline:transaction-order"
                                style={{ color: "white", fontSize: "20px" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  backgroundColor: "#282C2D",
                  color: "white",
                  "& .MuiIconButton-root, & .MuiTablePagination-selectIcon": {
                    color: "white",
                  },
                  "& .MuiIconButton-root": {
                    color: "white",
                  },
                }}
              />
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            padding: '10px 0px 10px 20px',
            backgroundColor: "#282C2D",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: '10px',
            width: 140,
            // "& .MuiMenuItem-root": {
            //   px: 1,
            //   typography: "body2",
            //   borderRadius: 0.75,
            // },
          },
        }}
      >
        {/* <Box
          style={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            alignItems:'center',
            gap:'10px',
            width:'100%',
            p:1,
            backgroundColor:'orange'
          }}
        > */}
        <MenuItem
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "blue",
            width: "100%",
          }}
          onClick={() => {
            handleExportExcel();
          }}
        >
          <Icon
            icon="vscode-icons:file-type-excel"
            style={{ marginRight: 10, fontSize: '20px' }}
          />
          <Typography style={{ fontWeight: 'bold', color: 'white', fontSize: '14px' }}>Excel</Typography>
        </MenuItem>
        <MenuItem
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "blue",
            width: "100%",
          }}
          onClick={() => {
            handleExportPdf();
          }}
        >
          <Icon
            icon="vscode-icons:file-type-pdf2"
            style={{ marginRight: 10, fontSize: '20px' }}
          />
          <Typography style={{ fontWeight: 'bold', color: 'white', fontSize: '14px' }}>Pdf</Typography>
        </MenuItem>
        {/* </Box> */}
      </Popover>
      <TransaksiModal
        open={openDetailTransaksiModal}
        close={() => setOpenDetailTransaksiModal(false)}
        dataTransaksi={selectedData}
        finishDate={finishDate}
        startDate={startDate}
      />
      <ToastContainer />
    </div>
  );
};

export default Report;