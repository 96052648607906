import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate dan useLocation
import styles from "./page.module.css";
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import easyticket from '../../assets/Icon/icon_easyticket.png'
import { brandColor, whiteColor } from '../Config/Color';

function TopNavbar() {
  const navigate = useNavigate(); // Gunakan useNavigate untuk navigasi
  const location = useLocation(); // Gunakan useLocation untuk mendapatkan lokasi saat ini

  const [user, setUser] = useState("");

  const item = localStorage.getItem("item");

  const getUsers = () => {
    const username = JSON.parse(item).username;
    setUser(username);
  };

  useEffect(() => {
    if (item) {
      getUsers();
    }
  }, [item]);

  const handleLogout = () => {
    localStorage.clear();
    setUser("");
    navigate('/')
  };

  return (
    <nav className={styles.container}>
      <div className={styles.leftNavbar}>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 'bolder',
            color: brandColor
          }}
        >
          easyticket.id
        </Typography>
      </div>
      <div className={styles.centerNavbar}>
        {user ? (
          <>
            <Button
              onClick={() => { navigate('/transaksi'); }}
              // variant={location.pathname === '/transaksi' ? 'outlined' : undefined}
              style={{
                borderColor: location.pathname === '/transaksi' ? brandColor : 'transparent',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/transaksi' ? brandColor : whiteColor, fontSize: '14px' }}>Transaksi</Typography>
            </Button>
            <Button
              onClick={() => { navigate('/daftartransaksi'); }}
              // variant={location.pathname === '/daftartransaksi' ? 'outlined' : undefined}
              style={{
                borderColor: location.pathname === '/daftartransaksi' ? brandColor : 'transparent',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/daftartransaksi' ? brandColor : whiteColor, fontSize: '14px', }}>Daftar Transaksi</Typography>
            </Button>
            <Button
              onClick={() => { navigate('/report'); }}
              // variant={location.pathname === '/report' ? 'outlined' : undefined}
              style={{
                borderColor: location.pathname === '/report' ? brandColor : 'transparent',
                borderRadius: '5px'
              }}
            >
              <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/report' ? brandColor : whiteColor, fontSize: '14px', padding: '5px 5px', }}>Laporan</Typography>
            </Button>
          </>
        ) : null}
      </div>
      <div className={styles.rightNavbar}>
        {user && (
          <>
            <span style={{ fontWeight: "bold", color: "white" }}>
              {user}
            </span>
            <Tooltip title="Log Out">
              <IconButton
                style={{
                  border: "solid 1px crimson",
                  width: "30px",
                  height: "30px",
                  padding: "4px",
                }}
                onClick={() => handleLogout()}
              >
                <Icon color="crimson" icon="ri:logout-circle-r-line" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </nav>
  );
}

export default TopNavbar;
