"use client";

import React, { useState, useEffect } from "react";
import axios from "axios";

// material-ui
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

// Toasty Alert
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// third party
import SuccessAlert from "../../components/ToastyAlert/SuccessAlert";
import ErrorAlert from "../../components/ToastyAlert/ErrorAlert";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { Formik } from "formik";
import LoadingButton from '@mui/lab/LoadingButton';
import { kasirLogin } from "../../components/Config/Api";
import { useNavigate } from "react-router-dom";
import { brandColor, brandColorImportant } from "../../components/Config/Color";


// ============================|| LOGIN ||============================ //

const Login = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validation, setValidation] = useState([]);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // console.log('item', item);
  const item = localStorage.getItem("item")


  useEffect(() => {
    if (item) {
      navigate('/transaksi')
    }
  }, [item]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '90vh',
        overflow: 'hidden'
      }}
    >
      <Paper
        elevation={10}
        sx={{
          backgroundColor: "#111",
          padding: "30px",
          width: "32%",
          maxWidth: "32%",
          color: "white",
        }}
      >
        <Box
          mb="30px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            Login <span style={{ color: brandColor }}>Kasir</span>
          </Typography>
        </Box>
        <Formik
          initialValues={{
            username: "",
            password: "",
            // submit: null,
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(30).required("Username wajib diisi"),
            password: Yup.string().max(30).required("Password wajib diisi"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setLoading(true);

            await axios
              .post(kasirLogin, {
                username: values.username,
                password: values.password,
              })
              .then((response) => {
                console.log("res", response.data);
                // setLoading(false)
                SuccessAlert({
                  message: `Hai ${response.data.user.username}, Semangat kerjanya ya :)`,
                  position: "top-center",
                })

                // const expSession = new Date().setMinutes(new Date().getMinutes() + 60)
                localStorage.setItem('item', JSON.stringify({
                  value: 'string',
                  username: response.data.user.username,
                  role: response.data.user.role,
                  user_id: response.data.user.user_id
                }));
                
                setTimeout(() => {
                  navigate('/transaksi')
                }, 2000);

              })
              .catch((error) => {
                setLoading(false);
                ErrorAlert({
                  message: error.response.data.message,
                  position: "top-center",
                });
                ErrorAlert({
                  message: error.response.data,
                  position: "top-center",
                });
                console.log("error", error.response.data);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel
                      sx={{ color: "white" }}
                      htmlFor="username-login"
                    >
                      Username
                    </InputLabel>
                    <OutlinedInput
                      id="username-login"
                      type="username"
                      value={values.username}
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Masukkan username anda . . ."
                      fullWidth
                      error={Boolean(touched.username && errors.username)}
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      inputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          borderColor: "white !important",
                        },
                        "&:hover fieldset": {
                          borderColor: brandColorImportant,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: brandColorImportant,
                        },
                      }}
                    />
                    {touched.username && errors.username && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-username-login"
                      >
                        {errors.username}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel
                      sx={{ color: "white" }}
                      htmlFor="password-login"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      error={Boolean(touched.password && errors.password)}
                      id="-password-login"
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <Icon
                                icon="mdi:eye-outline"
                                style={{ color: "white" }}
                              />
                            ) : (
                              <Icon
                                icon="mingcute:eye-close-line"
                                style={{ color: "white" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password anda. . ."
                      inputProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      inputLabelProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          borderColor: "white !important",
                        },
                        "&:hover fieldset": {
                          borderColor: brandColorImportant,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: brandColorImportant,
                        },
                      }}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Stack>
                </Grid>
                {errors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <LoadingButton
                    // disableElevation
                    type="submit"
                    fullWidth
                    size="large"
                    loading={loading}
                    loadingIndicator={
                      <Icon
                        icon="svg-spinners:6-dots-scale-middle"
                        color={brandColor}
                        style={{ fontSize: "25px" }}
                      />
                    }
                    variant="contained"
                    sx={{
                      backgroundColor:brandColor,
                      color:'white',
                      fontWeight: "bolder",
                    }}
                  >
                    Login
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
      </Paper>
    </Box>
  );
};

export default Login;
