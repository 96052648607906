import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import idLocale from "date-fns/locale/id";
import Router from './routes';
import TopNavbar from './components/Navbar/Navbar';

const App = () => {
  return (
    <div style={{
      backgroundColor: '#111',
      width: '100vw',
      height: '100vh'
    }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={idLocale}>
        <BrowserRouter>
          <TopNavbar />
          <Router />
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
