// pages/qrcode.js
import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import QRCode from "react-qr-code";

const QRCodeGenerator = ({ transactionId }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          marginBottom: "2px",
          backgroundColor: "white",
          height: "auto",
          margin: "0 auto",
          maxWidth: 100,
          width: "100%",
        }}
      >
        {transactionId && (
          <QRCode
            value={transactionId.toString()}
            size={200}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            viewBox={`0 0 256 256`}
          />
        )}
      </div>
      <div>
        <Typography
          style={{ textAlign: "center", fontSize: "10px" }}
        >
          {" "}
          Kode Transaksi : {transactionId}
        </Typography>
      </div>
      <div
        style={{ padding: '30px 0px 0px 0px' }}
      >
        <Typography
          style={{ textAlign: "center", fontSize: "10px" }}
        >
          Terimakasih atas kunjungannya
        </Typography>
        <Typography
            style={{ textAlign: "center", fontSize: "10px" }}
          >
            www.thebloomsbali.com
          </Typography>
        {/* <div
        style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}
        >
          <Icon icon={'mdi:web'} style={{ fontSize: '15px', marginRight:'5px' }} />
          <Typography
            style={{ textAlign: "center", fontSize: "10px" }}
          >
            thebloomsbali.com
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

export default QRCodeGenerator;