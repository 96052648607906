import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
} from "@mui/material";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import ErrorAlert from "../../components/ToastyAlert/ErrorAlert";
import SuccessAlert from "../../components/ToastyAlert/SuccessAlert";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { brandColor } from "../../components/Config/Color";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 430,
  bgcolor: "#282C2D",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  color: "white",
};

const DetailTransaksi = ({ open, close, selectedData, cart }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({
    namaTiket: "",
    totalQty: "",
    harga: "",
  });
  const [price, setPrice] = useState({
    totalPrice: 0,
  });

  const getData = () => {
    setNewData({
      namaTiket: selectedData.category_name,
      totalQty: "",
      harga: selectedData.total,
    });

    setPrice({
      totalPrice: 0,
    });
  };

  useEffect(() => {
    if (selectedData.ticketingcategory_id) {
      getData();
    }
  }, [selectedData.ticketingcategory_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("nama tiket", newData.namaTiket);
    // console.log("totalQty", newData.totalQty);
    // console.log("harga", newData.harga);
    // console.log("totalPrice", price.totalPrice);

    setLoading(true);

    if (newData.totalQty < 1) {
      ErrorAlert({
        message: 'Jumlah tiket tidak valid',
        position: 'top-center'
      })
      setLoading(false)
    } else {
      setTimeout(() => {
        cart?.push({
          tiket_id: selectedData.ticketingcategory_id,
          namaTiket: newData.namaTiket,
          totalQty: newData.totalQty,
          totalPrice: price.totalPrice,
        });
        setLoading(false);
        close();
        clearFormData();
        SuccessAlert({
          message: "Tiket berhasil ditambahkan",
          position: 'bottom-left'
        });
      }, 1500);
    }

    console.log("cart", cart);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const clearFormData = () => {
    setNewData({
      tiket_id: selectedData.ticketingcategory_id,
      namaTiket: selectedData.category_name,
      totalQty: "",
      harga: selectedData.total,
    });

    setPrice({
      totalPrice: 0,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={(e) => handleSubmit(e)}>
            <Box
              display="grid"
              gap="3px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              // p="10px"
              mt="5px"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nama Tiket"
                value={newData.namaTiket}
                name="namaTiket"
                autoComplete="off"
                required
                disabled
                sx={{
                  gridColumn: "span 4",
                  fontSize: "13px",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "white",
                  },
                  "& .MuiFilledInput-root.Mui-disabled:hover": {
                    "& fieldset": {
                      borderBottomColor: brandColor,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "white",
                  },
                  "& .MuiFilledInput-underline:before": {
                    borderBottomColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                    color: "white",
                  },
                  "& .MuiFilledInput-underline:hover:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:hover:after": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:after": {
                    borderBottomColor: brandColor,
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
              />

              <TextField
                variant="filled"
                type="number"
                label="Jumlah Tiket"
                placeholder="Masukkan jumlah tiket"
                value={newData.totalQty}
                onChange={(e) => {
                  handleChange(e);
                  if (newData.harga) {
                    var tempQty = e.target.value;
                    var cal = newData.harga * tempQty;
                    // console.log(cal);
                    setPrice({
                      totalPrice: cal ? cal : 0,
                    });
                  }
                }}
                name="totalQty"
                autoComplete="off"
                autoFocus
                required
                sx={{
                  gridColumn: "span 4",
                  mt: "30px",
                  "& .MuiFilledInput-input": {
                    color: "white",
                    // fontSize:'13px'
                  },
                  "& .MuiFilledInput-underline:before": {
                    borderBottomColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                    color: "white",
                  },
                  "& .MuiFilledInput-underline:hover:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:hover:after": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:after": {
                    borderBottomColor: brandColor,
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "MuiFilledInput-root",
                    focused: "MuiFilledInput-focused",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: "MuiInputLabel-root",
                    focused: "MuiInputLabel-focused",
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Harga"
                // placeholder="Jumlah tiket"
                value={ConvertIDR(newData.harga)}
                // onChange={(e) => handleChange(e)}
                name="harga"
                autoComplete="off"
                disabled
                sx={{
                  gridColumn: "span 4",
                  fontSize: "13px",
                  mt: "30px",
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "white",
                  },
                  "& .MuiFilledInput-root.Mui-disabled:hover": {
                    "& fieldset": {
                      borderBottomColor: brandColor,
                    },
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "white",
                  },
                  "& .MuiFilledInput-underline:before": {
                    borderBottomColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                    color: "white",
                  },
                  "& .MuiFilledInput-underline:hover:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:hover:after": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:before": {
                    borderBottomColor: brandColor,
                  },
                  "& .MuiFilledInput-underline:focus:after": {
                    borderBottomColor: brandColor,
                  },
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
              />
              <Box
                sx={{
                  gridColumn: "span 4",
                  mt: "30px",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  Total Harga :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    ml: "5px ",
                  }}
                >
                  {ConvertIDR(price.totalPrice)}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                // marginTop: '20px',
                display: "flex",
                justifyContent: "space-between",
                // marginRight: '10px',
                marginTop: "40px",
                marginBottom: "5px",
              }}
            >
              <Button
                color="error"
                variant="contained"
                size="medium"
                onClick={() => {
                  close();
                  // clearFormData();
                }}
                sx={{
                  marginRight: "15px",
                  width: "80px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  "&.Mui-disabled": {
                    color: "#111", // Set the font color to white when disabled
                  },
                }}
                disabled={loading}
              >
                Kembali
              </Button>
              <LoadingButton
                type="submit"
                size="medium"
                loading={loading}
                loadingIndicator={
                  <Icon
                    icon="svg-spinners:6-dots-scale-middle"
                    color={brandColor}
                    style={{ fontSize: "25px" }}
                  />
                }
                variant="contained"
                // color='warning'
                sx={{ fontWeight: "bold", backgroundColor: brandColor }}
              >
                Simpan
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default DetailTransaksi;
