import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import ConvertIDR from "../ConvertIDR/ConvertIDR";
// import ConvertIDR from "@/components/ConvertIDR/ConvertIDR";
import { Icon } from "@iconify/react";
import { useReactToPrint } from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import TransactionReceipt from "../../pages/transaksi/TransactionReceipt";
import { brandColor } from "../Config/Color";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "#282C2D",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  p: 3,
  color: "white",
};

const NotificationModal = ({
  open,
  close,
  success,
  dataTransaksi,
  grandTotal,
  transactionId,
  dibayar
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const receiptRef = useRef(); // Create a ref for the ReceiptContent component

  // Use the useReactToPrint hook to enable print functionality
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current, // Pass the ReceiptContent component to be printed
    documentTitle: "Struk Pembelian", // Set the document title for the print job
    onAfterPrint: () => {
      // This function will be called after the print job is completed
      // You can perform any cleanup or actions here
      success();
      close();
    },
    copyStyles: true,
  });

  return (
    <div>
      <Modal
        open={open}
        // onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="20px"
          >
            <Icon
              icon="icon-park-solid:success"
              // color="success"
              style={{ fontSize: "80px" }}
            />
            <Typography
              sx={{ fontWeight: "bold", fontSize: "20px" }}
            // color='success'
            >
              Transaksi Berhasil
            </Typography>
          </Box>
          {dataTransaksi.length !== 0 ? (
            <>
              <Box mt="30px">
                <Box align="left">
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "13px" }}
                        >
                          Daftar Pembelian
                        </Typography>
                      </Box>
                      <Box
                        width="30%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "13px" }}
                        >
                          Qty
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "13px" }}
                        >
                          Harga
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      variant="string"
                      sx={{
                        backgroundColor: "white",
                        borderBottomWidth: 1,
                        mt: "2px",
                      }}
                    />
                  </Box>

                  <Box mt="10px">
                    {dataTransaksi.map((data, index) => {
                      return (
                        <Box mt="3px" display="flex" width="100%" mb='10px'>
                          <Box
                            width="70%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            key={index}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              {data.namaTiket}
                            </Typography>
                          </Box>
                          <Box
                            width="30%"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              {data.totalQty}
                            </Typography>
                            <Typography sx={{ fontSize: "13px" }}>
                              {ConvertIDR(data.totalPrice)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                    <Divider
                      variant="string"
                      sx={{
                        backgroundColor: "white",
                        borderBottomWidth: 1,
                        mt: "2px",
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection='column'
                      justifyContent="space-between"
                      alignItems="center"
                      mt="10px"
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: "space-between",
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          Total
                        </Typography>
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          {ConvertIDR(grandTotal)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: "space-between",
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          Dibayar
                        </Typography>
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          {ConvertIDR(dibayar)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: "space-between",
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          Kembalian
                        </Typography>
                        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                          {ConvertIDR(dibayar - grandTotal)}
                        </Typography>
                      </Box>

                    </Box>
                  </Box>

                </Box>
              </Box>
              <Box
                mt="40px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="20px"
              >
                <Button
                  onClick={() => {
                    success();
                    close();
                  }}
                  size="small"
                  variant="contained"
                  color="error"
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Kembali
                </Button>
                <Button
                  onClick={handlePrint}
                  size="small"
                  variant="contained"
                  // color={brandColor}
                  sx={{
                    backgroundColor: brandColor,
                    fontWeight: 'bold'
                  }}
                >
                  Cetak Struk
                </Button>
              </Box>
            </>
          ) : undefined}
        </Box>
      </Modal>
      <div style={{ display: "none" }}>
        <TransactionReceipt
          ref={receiptRef}
          dataTransaksi={dataTransaksi}
          grandTotal={grandTotal}
          transactionId={transactionId}
          diBayar={dibayar}
        />
      </div>
    </div>
  );
};

export default NotificationModal;
