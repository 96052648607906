import React, { useEffect, useState, useRef, forwardRef } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
  Card,
  TablePagination,
  IconButton,
  Tooltip,
  Paper,
  Popover,
  MenuItem
} from "@mui/material";
import { Icon } from "@iconify/react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import SearchBar from "material-ui-search-bar";
import ConvertIDR from "../../components/ConvertIDR/ConvertIDR";
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import LogoParkways from '../../assets/Icon/logo-parkways.png'
import LogoWeb from '../../assets/Icon/web.png'
import ErrorAlert from "../../components/ToastyAlert/ErrorAlert";
import InfoAlert from "../../components/ToastyAlert/InfoAlert";
import moment from "moment";
import SearchBar from "material-ui-search-bar";
import "moment/locale/id";


moment.locale("id");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: "auto",
  bgcolor: "#282C2D",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  //   p: 3,
  color: "white",
};

const TransaksiModal = ({
  open,
  close,
  success,
  dataTransaksi,
  grandTotal,
  finishDate,
  startDate
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [searched, setSearched] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openPopover, setOpenPopover] = useState(null);

  const getData = () => {
    setFilteredData(dataTransaksi.details);
  };

  useEffect(() => {
    if (dataTransaksi.transaction_id) {
      getData();
    }
  }, [dataTransaksi.transaction_id]);

  console.log("data modal", dataTransaksi);

  const requestSearch = (searchedVal) => {
    setSearchQuery(searchedVal);
    const filteredRows = filteredData.filter((row) => {
      return (
        row.ticket_code.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.category_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.item_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.total.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setFilteredData(filteredRows);
    setPage(0); // Reset to the first page after filtering
  };

  const cancelSearch = () => {
    setSearchQuery("");
    setFilteredData(dataTransaksi.details);
    setSearched("");
    setPage(0); // Reset to the first page after canceling search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Fungsi untuk export data ke format file CSV
  const handleExportExcel = () => {
    if (filteredData.length !== 0) {
      // Membuat objek custom header
      const exportData = filteredData.map((item) => ({
        'Kode Transaksi': item.ticket_code,
        'Nama Tiket': item.category_name,
        'Nama Item': item.item_name,
        'Harga': ConvertIDR(item.total),
      }));

      // Menghitung total
      const grandTotal = filteredData.reduce((total, item) => total + parseInt(item.total, 10), 0);

      // Membuat objek totalRow
      const totalRow = {
        'Kode Transaksi': 'GrandTotal',
        'Nama Tiket': '',
        'Nama Item': '',
        'Harga': ConvertIDR(grandTotal),
      };

      // Menambahkan totalRow ke exportData
      exportData.push(totalRow);

      const workSheet = XLSX.utils.json_to_sheet(exportData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'LaporanDetailTransaksi');

      XLSX.writeFile(workBook, 'LaporanDetailTransaksi.xlsx');
      InfoAlert({
        message: 'Tersimpan di folder download',
      });
    } else {
      ErrorAlert({
        message: 'Gagal export data, tidak ada data',
      });
    }
  };

  // Fungsi untuk export data to PDF
  const handleExportPdf = () => {
    const doc = new JsPDF();

    const columns = [
      {
        title: "Kode Transaksi",
        field: "ticket_code",
      },
      {
        title: "Nama Tiket",
        field: "category_name",
      },
      {
        title: "Nama Item",
        field: "item_name",
      },
      {
        title: "Harga",
        field: "total",
      },
    ];

    // Mendefinisikan logo
    const logoUrl = LogoParkways; 
    const logoWidth = 16;
    const logoHeight = 16;

    if (filteredData.length !== 0) {
      const titleX = doc.internal.pageSize.getWidth() / 15.5; // Posisi horizontal (x-coordinate) di tengah halaman
      const titleY = 25; // Posisi vertikal (y-coordinate)

      doc.setFontSize(13);
      doc.setFont("helvetica", "bold");
      doc.text("LaporanDetailTransaksi", titleX, titleY);
      // doc.text('Laporan Pendapatan', 15, 8)

      // Menambahkan logo
      doc.addImage(logoUrl, "PNG", 155, 10, logoWidth, logoHeight);
      // doc.setFont('helvetica', 'bold');
      doc.setTextColor("#1E458C");
      doc.text("Parkways", 170, 18);
      doc.setFontSize(5);
      doc.text("Digital Parking Transformation", 170, 21.5);

      doc.setTextColor("black");
      doc.setFontSize(8);
      // doc.setFont('helvetica', 'bold')
      // doc.text(location.location_name ? `${location.location_name}` : 'Tidak ada nama lokasi', 14, 35);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.text(
        `Periode ${moment(startDate).format("Do MMM YYYY")} - ${moment(
          finishDate
        ).format("Do MMM YYYY")}`,
        14,
        39.5
      );

      // Menggambar garis bawah pada judul
      doc.setLineWidth(0.1);
      doc.line(
        13,
        titleY + 2.5,
        doc.internal.pageSize.getWidth() - 13,
        titleY + 2
      );

      const startY = 42;

      doc.autoTable({
        startY,
        showHead: "firstPage",
        rowPageBreak: "avoid",
        headStyles: {
          valign: "middle",
          halign: "left",
          fontSize: 9,
          cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
        },
        columnStyles: {
          ticket_code: {
            halign: "left",
            valign: "middle",
            fontSize: 9,
            // cellWidth: 55,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: "bold",
          },
          category_name: {
            halign: "left",
            valign: "middle",
            fontSize: 9,
            // cellWidth: 50,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          item_name: {
            halign: "left",
            valign: "middle",
            // cellWidth: 50,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
          },
          total: {
            halign: "right",
            valign: "middle",
            // cellWidth: 20,
            fontSize: 9,
            cellPadding: { top: 3, bottom: 3, left: 2, right: 2 },
            fontStyle: "bold",
          },
        },

        columns: columns.map((col) => ({
          header: col.title,
          dataKey: col.field,
        })),

        body: filteredData.map((data) => ({
          ticket_code: data.ticket_code,
          category_name: data.category_name,
          item_name: data.item_name,
          total: ConvertIDR(data.total),
        })),
      });

      const grandTotal = filteredData.reduce(
        (total, data) => total + parseInt(data.total, 10),
        0
      );

      // console.log('grandTotal', grandTotal);

      doc.autoTable({
        body: [
          [
            {
              content: "Grand Total",
              styles: {
                halign: "left",
                valign: "middle",
                fontSize: 9,
                fillColor: "#FFFFFF",
                lineWidth: { bottom: 0.1 },
                lineColor: "#000000",
                fontStyle: "bold",
              },
            },
            {
              content: ConvertIDR(grandTotal),
              styles: {
                halign: "right",
                valign: "middle",
                fontSize: 9,
                fillColor: "#FFFFFF",
                lineWidth: { bottom: 0.1 },
                lineColor: "#000000",
                fontStyle: "bold",
              },
            },
          ],
        ],
      });

      // Footer Section
      // Mendapatkan tanggal saat ini
      const currentDate = moment(new Date()).format("YYYY-MM-DD");
      const currentTime = moment(new Date()).format("HH:mm:ss");

      // Mendefinisikan ikon web
      const webIconUrl = LogoWeb;
      const webIconWidth = 4;
      const webIconHeight = 4;

      // Menambahkan ikon web di sebelah kiri www.bssparking.com
      const webIconX1 = 127; // Koordinat X untuk ikon web
      const webIconY = doc.internal.pageSize.getHeight() - 13;

      // Memuat ikon web
      doc.addImage(
        webIconUrl,
        "PNG",
        webIconX1,
        webIconY,
        webIconWidth,
        webIconHeight
      );

      // Mendefinisikan teks footer
      const leftFooterText = `Dicetak pada ${currentDate} ${currentTime}`;
      const rightFooterText1 = "www.parkways.id";
      const rightFooterText2 = "www.bssparking.com";

      // Menambahkan footer
      doc.setFontSize(8);
      doc.setTextColor("gray");

      // Teks footer di sebelah kiri
      doc.text(
        leftFooterText,
        8 + webIconWidth + 2,
        doc.internal.pageSize.getHeight() - 10
      );
      doc.setFont("helvetica", "bold");

      // Teks footer di sebelah kanan www.parkways.id
      const rightFooterTextWidth1 =
        (doc.getStringUnitWidth(rightFooterText1) *
          doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const rightFooterTextX1 =
        doc.internal.pageSize.getWidth() - rightFooterTextWidth1 - 13;
      doc.text(
        rightFooterText1,
        rightFooterTextX1,
        doc.internal.pageSize.getHeight() - 10
      );

      // Menambahkan ikon web di sebelah kanan www.parkways.id
      const webIconX2 = rightFooterTextX1 - webIconWidth - 2;
      doc.addImage(
        webIconUrl,
        "PNG",
        webIconX2,
        webIconY,
        webIconWidth,
        webIconHeight
      );

      // Teks footer di sebelah kanan www.bssparking.com
      const rightFooterTextWidth2 =
        (doc.getStringUnitWidth(rightFooterText2) *
          doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const rightFooterTextX2 = webIconX2 - rightFooterTextWidth2 - 6;
      doc.text(
        rightFooterText2,
        rightFooterTextX2,
        doc.internal.pageSize.getHeight() - 10
      );

      doc.save("LaporanDetailTransaksi.pdf");
      InfoAlert({
        message: "Laporan tersimpan di folder download",
      });
    } else {
      ErrorAlert({
        message: "Gagal export data, tidak ada data",
      });
    }
  };

  const handleOpenMenuExportData = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {dataTransaksi.length !== 0 ? (
            <TableContainer component={Paper}>
              <Box
                sx={{
                  p: "20px 20px 10px 20px",
                  backgroundColor: "#282C2D",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <SearchBar
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                    style={{ width: "50%" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Export">
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={handleOpenMenuExportData}
                    >
                      <Icon
                        icon="line-md:download-loop"
                        style={{ fontSize: "25px", color: "#fff" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Table
                sx={{ minWidth: 650, backgroundColor: "#282C2D" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                      No
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Kode Transaksi
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nama Tiket
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "white", fontWeight: "bold" }}
                    >
                      Nama Item
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        pr: "40px",
                      }}
                    >
                      Harga
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "white" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.ticket_code}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.category_name}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "white" }}>
                          {row.item_name}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: "white", fontWeight: "bold" }}
                        >
                          {ConvertIDR(row.total)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  backgroundColor: "#282C2D",
                  color: "white",
                  "& .MuiIconButton-root, & .MuiTablePagination-selectIcon": {
                    color: "white",
                  },
                  "& .MuiIconButton-root": {
                    color: "white",
                  },
                }}
              />
            </TableContainer>
          ) : undefined}
        </Box>
      </Modal>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            padding: "10px 0px 10px 20px",
            backgroundColor: "#282C2D",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: 140,
            // "& .MuiMenuItem-root": {
            //   px: 1,
            //   typography: "body2",
            //   borderRadius: 0.75,
            // },
          },
        }}
      >
        <MenuItem
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "blue",
            width: "100%",
          }}
          onClick={() => {
            handleExportExcel();
          }}
        >
          <Icon
            icon="vscode-icons:file-type-excel"
            style={{ marginRight: 10, fontSize: "20px" }}
          />
          <Typography
            style={{ fontWeight: "bold", color: "white", fontSize: "14px" }}
          >
            Excel
          </Typography>
        </MenuItem>
        <MenuItem
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            // backgroundColor: "blue",
            width: "100%",
          }}
          onClick={() => {
            handleExportPdf();
          }}
        >
          <Icon
            icon="vscode-icons:file-type-pdf2"
            style={{ marginRight: 10, fontSize: "20px" }}
          />
          <Typography
            style={{ fontWeight: "bold", color: "white", fontSize: "14px" }}
          >
            Pdf
          </Typography>
        </MenuItem>
      </Popover>
    </div>
  );
};

export default TransaksiModal;
