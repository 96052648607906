import { Navigate, useRoutes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import Transaksi from './pages/transaksi/Transaksi';
import DaftarTransaksi from './pages/daftartransaksi/DaftarTransaksi';
import Report from './pages/laporan/Report';
// layouts


// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
      {
          path: '/',
          element: <Login />,
      },
      {
          // path: 'dashboard',
          //   element: <DashboardLayout />,
          children: [
              { path: '/transaksi', element: <Transaksi />, index: true },
              { path: '/daftartransaksi', element: <DaftarTransaksi />, index: true },
              { path: '/report', element: <Report />, index: true },
              // { path: '/user', element: <UserPage /> },
          ],
      },
  ]);


  return routes;
}


